import React from "react";
import type { HeadFC } from "gatsby";
import {Epiphone} from "../../screens/BrandsScreen/Brands";

const EpiphoneBrandPage = () => {
    return <Epiphone />;
};

export default EpiphoneBrandPage;

export const Head: HeadFC = () => <title>Our Brands</title>;
